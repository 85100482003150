import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { console_log, DataProvider } from 'src/providers/data/data';
import { CallapiService } from 'src/providers/rest/callapi.service';

@Component({
  selector: 'app-list-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss'],
})
export class ListEditComponent implements OnInit {
  public User_Code;
  public User_Last_Name;
  public User_First_Name;
  public Mail_Address;
  public User_Id = '';
  public User_Id_1 = '';
  public User_Id_2 = '';
  public User_Id_3 = '';
  public Use_Status;
  public Confirm_Code = '';
  public Name;
  public Type;
  public Corporation_Form;
  constructor(private router: Router,
    private route: ActivatedRoute,
    public data: DataProvider,
    public rest: CallapiService,
    private translate: TranslateService,
    private spinner: NgxSpinnerService) {}

  ngOnInit() {
    this.data.menuPageTitle = 'contract-user-list';
    this.User_Code = this.route.snapshot.paramMap.get('user_code');
    this.spinner.show();
    var type = JSON.parse(localStorage.getItem('loginData')).data.corporation_no.split('');
    this.Corporation_Form = JSON.parse(localStorage.getItem('loginData')).data.corporation_form;
    this.Type = type[0];
    this.rest.Get_MembersId(this.data.uuid, this.User_Code).subscribe(
      (res)=>{
        console_log(res);
        this.User_Last_Name = res.data.user_last_name;
        this.User_First_Name = res.data.user_first_name;
        this.Mail_Address = res.data.mail_address;
        this.Use_Status = res.data.use_status;
        // this.Confirm_Code = res.data.confirm_code;
        if(this.Corporation_Form != '3'){
          this.User_Id = res.data.user_no.split('-');
          this.User_Id_1 = this.User_Id[0];
          this.User_Id_2 = this.User_Id[1];
          this.User_Id_3 = this.User_Id[2];
        } else {
          this.User_Id = res.data.user_no.split('-');
          this.User_Id_1 = res.data.user_no;
          this.User_Id_2 = '';
          this.User_Id_3 = '';
        }

        this.spinner.hide();
      },
      (err)=>{
        console_log(err);
        this.spinner.hide();
        this.router.navigate(['/contract-err', {code: err.error.error.code}]);
      }
    );
    this.widthChanger('main-header', 915);
    this.widthChanger('content-wrapper', 915);
  }

  back(id){
    if(id == 1){
      this.router.navigate(['/contract-user-list-detail',{user_code: this.User_Code}]);
    }else{
      document.getElementById('changeuserinfo').style.display='block';
      document.getElementById('changeuserinfoconfirm').style.display='none';
      document.getElementById('changeuserinfofinish').style.display='none';
    }
  }
  
  dataValidation() {
    let err = false;
    let errTxt = '';
    if (this.Corporation_Form == '1' || this.Corporation_Form == '2' ) {
      if(this.User_Id_1 == null || this.User_Id_1 == ''){
        err = true;
        this.translate
          .get('MEMBER_VALIDATION.ERROR_EMPLOYEE_NUMBER_CORP_A')
          .subscribe((res) => (errTxt = res));
        }
      // if(this.User_Id_3 == null || this.User_Id_3 == ''){
      //   err = true;
      //   this.translate
      //     .get('MEMBER_VALIDATION.ERROR_EMPLOYEE_BRANCH_NUMBER')
      //     .subscribe((res) => (errTxt = res));
      //   }
      if(this.User_Id_2 == null || this.User_Id_2 == ''){
      err = true;
      this.translate
        .get('MEMBER_VALIDATION.ERROR_NUMBER')
        .subscribe((res) => (errTxt = res));
      }


    }
    if (this.Corporation_Form == '3') {
      if (this.User_Id_1 == null || this.User_Id_1 == '') {
        err = true;
        this.translate
          .get('MEMBER_VALIDATION.ERROR_EMPLOYEE_NUMBER_CORP_A')
          .subscribe((res) => (errTxt = res));
      }
    }
    let EMAIL_REGEXP = /^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i;
    if (this.Mail_Address == null || this.Mail_Address == '' || this.Mail_Address == undefined) {
      err = true;
      this.translate
        .get('MEMBER_VALIDATION.ERROR_MAIL_ADDRESS')
        .subscribe((res) => (errTxt = res));
    }else if (this.Mail_Address != "" && (this.Mail_Address.length <= 5 || !EMAIL_REGEXP.test(this.Mail_Address))) {
      err = true;
      this.translate
        .get('MEMBER_VALIDATION.ERROR_MAIL_ADDRESS')
        .subscribe((res) => (errTxt = res));
    }
    if (this.User_First_Name == null || this.User_First_Name == '') {
      err = true;
      this.translate
        .get('MEMBER_VALIDATION.ERROR_USER_FIRST_NAME')
        .subscribe((res) => (errTxt = res));
    }
    if (this.User_Last_Name == null || this.User_Last_Name == '') {
      err = true;
      this.translate
        .get('MEMBER_VALIDATION.ERROR_USER_LAST_NAME')
        .subscribe((res) => (errTxt = res));
    }
    if (err) {
      document.getElementById('errormsgsett').innerHTML = errTxt;
      document.getElementById('errormsgsett').style.display = 'block';
      return false;
    }
    return true;
  }
  input(){
      const body = {
        data: {
          user_uuid: this.data.uuid,
          user_code: this.User_Code,
          user_last_name: this.User_Last_Name,
          user_first_name: this.User_First_Name,
          mail_address: this.Mail_Address,
          user_id_1: this.User_Id_1,
          user_id_2: this.User_Id_2,
          user_id_3: this.User_Id_3,
        },
      };
    console_log(body);
    if (!this.dataValidation()) {
      return true;
    } else {
      document.getElementById('changeuserinfo').style.display='none';
      document.getElementById('changeuserinfoconfirm').style.display='block';
      document.getElementById('changeuserinfofinish').style.display='none';
    }
  }
  finish(){

    // if (this.Corporation_Form == '3') {
    //   var tmp = this.User_Id_1.split('-');
    //   this.User_Id_1 = tmp[0];
    // }
    
    this.spinner.show();
    this.rest.Update_Members(
      this.data.uuid,
      this.User_Code,
      this.User_Last_Name,
      this.User_First_Name,
      this.Mail_Address,
      this.User_Id_1,
      this.User_Id_2,
      this.User_Id_3
    ).subscribe(
      (res)=>{
        console_log(res);
        document.getElementById('changeuserinfo').style.display='none';
        document.getElementById('changeuserinfoconfirm').style.display='none';
        document.getElementById('changeuserinfofinish').style.display='block';
        this.spinner.hide();
      },
      (err)=>{
        console_log(err);
        this.spinner.hide();
        this.router.navigate(['/contract-err', {code: err.error.error.code, user_id: this.User_Id_1}]);
      }
    );
  }
  list(){
    this.router.navigate(['/contract-user-list-detail',{user_code: this.User_Code}]);
  }
  
  widthChanger(className, width) {
    const headers = document.getElementsByClassName(className);
        for (let i = 0; i < headers.length; i++) {
          const header = headers[i] as HTMLElement;
          header.style.minWidth = width.toString() + 'px';
      }
  }
  CharCode(){
    if(this.Corporation_Form != '3'){
      if(this.User_Id_1.indexOf('-') > -1) {
        this.User_Id_1 = this.User_Id_1.replace(/-/gi, '');
      }
      if(this.User_Id_2.indexOf('-') > -1) {
        this.User_Id_2 = this.User_Id_2.replace(/-/gi, '');
      }
      if(this.User_Id_3.indexOf('-') > -1) {
        this.User_Id_3 = this.User_Id_3.replace(/-/gi, '');
      }

    }
  }
}
