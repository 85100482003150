import { Injectable } from '@angular/core';
import { DataProvider } from '../data/data';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { API_URL } from '../../environments/environment';

@Injectable()
export class CallapiService {
  constructor(private data: DataProvider, public http: HttpClient) {}
  // C001
  public Upload_Regcertificates(
    uuid: string,
    user_uuid: string,
    Pdf_Binary: string
  ): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      authorization: localStorage.getItem('token'),
    });
    const body = {
      data: {
        uuid: uuid,
        user_uuid: user_uuid,
        pdf_binary: Pdf_Binary,
      },
    };
    return this.http.post(API_URL + '/regcertificates/upload/', body, {
      responseType: 'json',
      headers,
    });
  }
    // C002
    public Download_Regcertificates(
      user_uuid: string,
      uuid: string
    ): Observable<any> {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        authorization: localStorage.getItem('token'),
      });
      const body = {
        data: {
          user_uuid: user_uuid,
          uuid: uuid,
        },
      };
      return this.http.post(API_URL + '/regcertificates/download/', body, {
        responseType: 'json',
        headers,
      });
    }
  // D001
  public Get_Screenauth(uuid: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      authorization: localStorage.getItem('token'),
    });
    const body = {
      data: {
        user_uuid: uuid,
      },
    };
    return this.http.post(API_URL + '/screenauth/', body, {
      responseType: 'json',
      headers,
    });
  }


  //E002 begin
  public Get_ContractsId(user_uuid: string, uuid): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      authorization: localStorage.getItem('token'),
    });
    const body = {
      data: {
        user_uuid: user_uuid,
        uuid:uuid,
      }
    }
    return this.http.post(API_URL + '/contracts/search/id', body, {
      responseType: 'json',
      headers,
    });
  }
  //E002 end


  //E003 begin
  public Get_LoginData(user_uuid: string, mail_address: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      authorization: localStorage.getItem('token'),
    });
    const body = {
      data: {
        user_uuid: user_uuid,
        mail_address: mail_address,
      },
    };
    return this.http.post(API_URL + '/contracts/search/login', body, {
      responseType: 'json',
      headers,
    });
  }
  //E003 end

  // E005
  public Update_Contracts(
    user_uuid: string,
    uuid: string,
    start_date: string,
    end_date: string,
    corporate_status: string,
    company_name: string,
    company_name_kana: string,
    postcode: string,
    address_1: string,
    address_2: string,
    address_3: string,
    tel: string,
    mail_address: string,
    staff_last_name: string,
    staff_first_name: string,
    staff_last_name_kana: string,
    staff_first_name_kana: string,
    staff_post: string,
    staff_position: string,
    corporation_form: string,
    users: string,
    acquired_code: string,
    history_flg: string,
    call_flg: string,
    health_code: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      authorization: localStorage.getItem('token'),
    });
    const body = {
      data:{
        user_uuid: user_uuid,
        uuid: uuid,
        start_date: start_date,
        end_date: end_date,
        corporate_status: corporate_status,
        company_name: company_name,
        company_name_kana: company_name_kana,
        postcode: postcode,
        address_1: address_1,
        address_2: address_2,
        address_3: address_3,
        tel: tel,
        mail_address: mail_address,
        staff_last_name: staff_last_name,
        staff_first_name: staff_first_name,
        staff_last_name_kana: staff_last_name_kana,
        staff_first_name_kana: staff_first_name_kana,
        staff_post: staff_post,
        staff_position: staff_position,
        corporation_form: corporation_form,
        users: users,
        acquired_code: acquired_code,
        history_flg: history_flg,
        call_flg: call_flg,
        health_code: health_code
      }
    };
    return this.http.post(API_URL + '/contracts/id', body, {
      responseType: 'json',
      headers,
    });
  }
  
  // E010 begin
  public Get_UnpaidUser(
    user_uuid: string,
    corporation_no: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      authorization: localStorage.getItem('token'),
    });
    const body = {
      data:{
        user_uuid: user_uuid,
        corporation_no: corporation_no
      }
    };
    return this.http.post(API_URL + '/contracts/search/unpaid/user/', body, {
      responseType: 'json',
      headers,
    });
  }
  // E010 end 

  // F001 begin
  public Get_Members(user_uuid: string, corporation_no: string, name: string, user_no: string, use_status: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      authorization: localStorage.getItem('token'),
    });
    const body = {
      data: {
        user_uuid: user_uuid,
        corporation_no: corporation_no,
        name: name,
        user_no: user_no,
        use_status: use_status,
        // confirm_flg: confirm_flg,
      },
    };
    return this.http.post(API_URL + '/members/search', body, {
      responseType: 'json',
      headers,
    });
  }
  // F001 end
  
  // F002 begin
  public Get_MembersId(user_uuid: string, user_code: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      authorization: localStorage.getItem('token'),
    });
    const body = {
      data: {
        user_uuid: user_uuid,
        user_code: user_code,
      },
    };
    return this.http.post(API_URL + '/members/search/id', body, {
      responseType: 'json',
      headers,
    });
  }
  // F002 end
  // F003 begin
  public Create_Members(
    user_uuid: string, 
    corporation_no: string, 
    user_last_name: string, 
    user_first_name: string, 
    mail_address: string, 
    user_id_1: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      authorization: localStorage.getItem('token'),
    });
    const body = {
      data: {
        user_uuid: user_uuid,
        corporation_no: corporation_no,
        user_last_name: user_last_name,
        user_first_name: user_first_name,
        mail_address: mail_address,
        user_id_1: user_id_1,
      },
    };
    return this.http.post(API_URL + '/members', body, {
      responseType: 'json',
      headers,
    });
  }
  // F003 end

  // F004 begin
  public Update_Members(user_uuid: string, 
    user_code: string, 
    user_last_name: string, 
    user_first_name: string, 
    mail_address: string,
    user_id_1: string,
    user_id_2: string,
    user_id_3: string,): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      authorization: localStorage.getItem('token'),
    });
    const body = {
      data: {
        user_uuid: user_uuid,
        user_code: user_code,
        user_last_name: user_last_name,
        user_first_name: user_first_name,
        mail_address: mail_address,
        user_id_1: user_id_1,
        user_id_2: user_id_2,
        user_id_3: user_id_3,
      },
    };
    return this.http.post(API_URL + '/members/id', body, {
      responseType: 'json',
      headers,
    });
  }
  // F004 end
  
  // F005 begin
  public Delete_Members(user_uuid: string, user_code: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      authorization: localStorage.getItem('token'),
    });
    const body = {
      data: {
        user_uuid: user_uuid,
        user_code: user_code,
      },
    };
    return this.http.post(API_URL + '/members/status/id', body, {
      responseType: 'json',
      headers,
    });
  }
  // F005 end
  // F006 begin
  public Send_AuthMail(user_uuid: string, user_code: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      authorization: localStorage.getItem('token'),
    });
    const body = {
      data: {
        user_uuid: user_uuid,
        user_code: user_code,
      },
    };
    return this.http.post(API_URL + '/members/auth/id', body, {
      responseType: 'json',
      headers,
    });
  }
  // F006 end
  // F007 begin
  public Send_AuthMailAll(user_uuid: string, send_data: any): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      authorization: localStorage.getItem('token'),
    });
    const body = {
      data: {
        user_uuid: user_uuid,
        send_data: {
          user_data: send_data
        }
      },
    };
    return this.http.post(API_URL + '/members/auth', body, {
      responseType: 'json',
      headers,
    });
  }
  // F007 end 
  // F008 begin
  public Create_MembersAll(user_uuid: string, corporation_no: string, csv_name: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      authorization: localStorage.getItem('token'),
    });
    const body = {
      data:{
        user_uuid: user_uuid,
        corporation_no: corporation_no,
        csv_name: csv_name,
      }
    };
    return this.http.post(API_URL + '/members/all', body, {
      responseType: 'json',
      headers,
    });
  }
  // F008 end 
  // G001 begin
  public Get_UseHistoryData(user_uuid: string, casio_id: string, start_date: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      authorization: localStorage.getItem('token'),
    });
    const body = {
      data:{
        user_uuid: user_uuid,
        casio_id: casio_id,
        start_date: start_date
      },
    };
    return this.http.post(API_URL + '/admhistory/id', body, {
      responseType: 'json',
      headers,
    });
  }
  // G001 end

  // J001 begin
  public Get_Notice(user_uuid: string, scope: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      authorization: localStorage.getItem('token'),
    });
    const body = {
      data: {
        user_uuid: user_uuid,
        scope: scope,
      },
    };
    return this.http.post(API_URL + '/notifications', body, {
      responseType: 'json',
      headers,
    });
  }
  // J001 end

  // I003 begin
  public Send_MailChangeMail(user_uuid: string, uuid: string, new_email: string, time: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      authorization: localStorage.getItem('token'),
    });
    const body = {
        data:{
            user_uuid: user_uuid,
            uuid: uuid,
            new_email: new_email,
            time: time
        }
    };
    return this.http.post(API_URL + '/cognito/user/sendchangemail', body, {
      responseType: 'json',
      headers,
    });
  }
  // I003 end 

   // I004 begin
   public Update_CognitoMail(crypto_uuid: string, crypto_pattern: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      authorization: localStorage.getItem('token'),
    });
    const body = {
        data:{
             crypto_uuid: crypto_uuid,
             crypto_pattern: crypto_pattern
        }    
    };
    return this.http.post(API_URL + '/cognito/user/changemail', body, {
      responseType: 'json',
      headers,
    });
  }
  // I004 end 
   // L001 begin
   public Unpaid_User_Payment(user_uuid: string, casio_id: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      authorization: localStorage.getItem('token'),
    });
    const body = {
        data:{
             user_uuid: user_uuid,
             casio_id: casio_id
        }
    };
    return this.http.post(API_URL + '/payment', body, {
      responseType: 'json',
      headers,
    });
  }
  // L001 end 

}
