import { Component, OnInit, Renderer2, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss'],
})
export class ErrorNetworkComponent {
  public changePasswordForm: FormGroup;
  public error_code;
  public uuid;
  public logoUrl;

  constructor(
    private renderer: Renderer2,
    public translate: TranslateService,
    private route: ActivatedRoute,
    private router: Router,
  ) {}

  reload () {
    window.location.href = '/';
  }
}
