<div class="main-padding">
  <h3 class="header-bottom">{{ 'SIDEBAR_MENU.REGISTRATION_LIST' | translate }}</h3>
  <section class="content" id="user">
    <div class="card-body">
      <div class="row">
        <div class="input-group company-name">
          <input 
          type="text" 
          class="form-control"
          id="Full_Name"
          [(ngModel)]="Full_Name"
          placeholder="{{ 'REGISTRATION_LIST.NAME' | translate }}"
          >
          <img *ngIf="!Full_Name" src="assets/icons/icon.svg" class="icon"/>
        </div>
        <!-- <div class="input-group col-4">
          <input type="text" class="form-control" placeholder="{{ 'COMMON_WORDS.MAIL_ADDRESS' | translate }}">
          <img src="assets/icons/msg.svg" class="icon"/>
        </div> -->
        <div class="input-group company-name1">
          <input
          type="text"
          class="form-control"
          id="Number"
          [(ngModel)]="Number"
          maxlength="30"
          placeholder="{{ 'COMMON_WORDS.NUMBER' | translate }}"
          >
          <img *ngIf="!Number" src="assets/icons/bookmrk.svg" class="icon"/>
        </div>
      </div>

      <!-- 状態 -->
      <div class="row checkbox-top">
        <div class="column-check1">
          <div class="text-label">
            <label class="ftext"> {{'REGISTRATION_LIST.STATUS' | translate }}</label>
          </div>
        </div>

        <div class="column-check">
          <div class="form-group">
            <div class="custom-control custom-checkbox">
              <input class="custom-control-input custom-control-input-danger custom-control-input-outline"
               type="checkbox" 
               id="Use_Status_1" 
               name="customRadio1" 
              [(ngModel)]="Use_Status_1">
              <label for="Use_Status_1" class="custom-control-label radiochecklbl_margintop ftext">{{ 'REGISTRATION_LIST.NOT_MEMBER' | translate }}</label>
            </div>
          </div>
        </div>
        <div class="column-check">
          <div class="form-group">
            <div class="custom-control custom-checkbox">
              <input class="custom-control-input custom-control-input-danger custom-control-input-outline"
               type="checkbox"
                id="Use_Status_2"
                name="customRadio1"
                [(ngModel)]="Use_Status_2">
              <label for="Use_Status_2" class="custom-control-label radiochecklbl_margintop ftext">{{ 'REGISTRATION_LIST.ONGOING' | translate }}</label>
            </div>
          </div>
        </div>
        <div class="column-check">
          <div class="form-group">
            <div class="custom-control custom-checkbox">
              <input class="custom-control-input custom-control-input-danger custom-control-input-outline"
                  type="checkbox"
                  id="Use_Status_3"
                  name="customRadio1"
                  [(ngModel)]="Use_Status_3">
              <label for="Use_Status_3" class="custom-control-label radiochecklbl_margintop ftext">{{ 'REGISTRATION_LIST.RECESS' | translate }}</label>
            </div>
          </div>
        </div>
        <div class="column-check">
          <div class="form-group">
            <div class="custom-control custom-checkbox">
              <input class="custom-control-input custom-control-input-danger custom-control-input-outline"
                  type="checkbox"
                  id="Use_Status_4"
                  name="customRadio1"
                  [(ngModel)]="Use_Status_4"
                 >
              <label for="Use_Status_4" class="custom-control-label radiochecklbl_margintop ftext">{{ 'REGISTRATION_LIST.WITHDRAWAL' | translate }}</label>
            </div>
          </div>
        </div>
        <div class="column-check btn-search-top">
          <a target="_blank" class="btn-search" (click)="Search()"
            >{{ 'CONTRACT_APP_LIST.SEARCH_BUTTON' | translate }}
            <img
              style="margin-left: 15px; right: 0%; margin-top: -3px"
              src="assets/icons/srch.svg"
            />
          </a>
        </div>
      </div>
      <!-- 認証番号 -->
      <!-- <div class="row" *ngIf="Use_Status_1">
        <div class="column-check1">
          <div class="text-label">
            <label class="ftext"> {{'REGISTRATION_LIST.AUTHENTICATION_CODE' | translate }}</label>
          </div>
        </div>
        <div class="column-check">
          <div class="form-group">
            <div class="custom-control custom-checkbox">
              <input class="custom-control-input custom-control-input-danger custom-control-input-outline"
              type="checkbox" 
              id="Confirm_1"
              name="customRadio2" 
              [(ngModel)]="Confirm_1"
              >
              <label for="Confirm_1" class="custom-control-label radiochecklbl_margintop ftext">{{ 'REGISTRATION_LIST.SENT' | translate }}</label>
            </div>
          </div>
        </div>
        <div class="column-check">
          <div class="form-group">
            <div class="custom-control custom-checkbox">
              <input class="custom-control-input custom-control-input-danger custom-control-input-outline"
              type="checkbox"
              id="Confirm_0"
              name="customRadio2"
              [(ngModel)]="Confirm_0">
              <label for="Confirm_0" class="custom-control-label radiochecklbl_margintop ftext">{{ 'REGISTRATION_LIST.UNSENT' | translate }}</label>
            </div>
          </div>
        </div>
      </div> -->
      <!-- <label class="limit-500" id="limit500">
        {{'COMMON.SEARCH_LIMIT_500' | translate}}
      </label> -->

      <div class="row select-radio-top">
        <div class="column-check">
          <div class="form-group">
            <div class="custom-control custom-radio">
              <input
                class="custom-control-input custom-control-input-danger custom-control-input-outline"
                type="radio"
                checked
                id="r1"
                name="customRadio" (click)="selectedRadios(10)"
              />
              <label for="r1" class="custom-control-label ftext">{{
                'COMMON.CASE_10' | translate
              }}</label>
            </div>
          </div>
        </div>
        <div class="column-check">
          <div class="form-group">
            <div class="custom-control custom-radio">
              <input
                class="custom-control-input custom-control-input-danger custom-control-input-outline"
                type="radio"
                id="r2"
                name="customRadio" (click)="selectedRadios(20)"
              />
              <label for="r2" class="custom-control-label ftext">{{
                'COMMON.CASE_20' | translate
              }}</label>
            </div>
          </div>
        </div>
        <div class="column-check">
          <div class="form-group">
            <div class="custom-control custom-radio">
              <input
                class="custom-control-input custom-control-input-danger custom-control-input-outline"
                type="radio"
                id="r3"
                name="customRadio" (click)="selectedRadios(50)"
              />
              <label for="r3" class="custom-control-label ftext">{{
                'COMMON.CASE_50' | translate
              }}</label>
            </div>
          </div>
        </div>
        <div class="column-check">
          <div class="form-group">
            <div class="custom-control custom-radio">
              <input
                class="custom-control-input custom-control-input-danger custom-control-input-outline"
                type="radio"
                id="r4"
                name="customRadio" (click)="selectedRadios(100)"
              />
              <label for="r4" class="custom-control-label ftext">{{
                'COMMON.CASE_100' | translate
              }}</label>
            </div>
          </div>
        </div>
      </div>
      <!-- 4 -->
      <section class="content"> 
        <div class="container-fluid" style="color: black;">
          <div class="row table-width">
            <table id="example2" class="table table-bordered" style="background-color: white;min-width: 600px">
              <thead>
                <tr>
                  <th class="ftext">{{ 'REGISTRATION_LIST.CONTRACTOR_NAME' | translate }}</th>
                  <th class="ftext">{{ 'COMMON_WORDS.NUMBER' | translate }}</th>
                  <th class="ftext">{{ 'COMMON_WORDS.USAGE_FORM' | translate }}</th>
                  <th class="ftext">{{ 'REGISTRATION_LIST.APP_START' | translate }}</th>
                  <th class="ftext">{{ 'REGISTRATION_LIST.APP_END' | translate }}</th>
                  <th class="ftext" *ngIf="button">{{ 'COMMON_WORDS.USER_DETAIL' | translate }}</th>
                  <th class="ftext" *ngIf="history_button && History_Flg != '0'">{{ 'REGISTRATION_LIST.USAGE_HISTORY' | translate }}</th>
                  <th class="ftext">{{ 'REGISTRATION_LIST.USE_COUNT' | translate }}</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let list of items">
                  <td class="tdata-1">{{list.name}}</td>
                  <td class="tdata-2">{{list.user_no}}</td>
                  <td class="CellWithComment tdata-3" *ngIf="list.use_status == '1'">
                      {{ 'REGISTRATION_LIST.NOT_MEMBER' | translate }}
                    <span class="CellComment" *ngIf="list.confirm_flg == '0'">
                      {{ 'REGISTRATION_LIST.AUTHENTICATION_CODE_NOT_SENT' | translate }}
                    </span>
                    <span class="CellComment" *ngIf="list.confirm_flg == '1'">
                      {{ 'REGISTRATION_LIST.AUTHENTICATION_CODE_SENT' | translate }}
                    </span>
                  </td>
                  <td class="tdata-3" *ngIf="list.use_status == '2'">{{ 'REGISTRATION_LIST.ONGOING' | translate }}</td>
                  <td class="tdata-3" *ngIf="list.use_status == '3'">{{ 'REGISTRATION_LIST.RECESS' | translate }}</td>
                  <td class="tdata-3" *ngIf="list.use_status == '4'">{{ 'REGISTRATION_LIST.WITHDRAWAL' | translate }}</td>
                  <td class="tdata-5">{{list.app_start}}</td>
                  <td class="tdata-5">{{list.app_end}}</td>
                  <td class="tdata-4" *ngIf="button">
                    <button (click)="details(list.user_code)" type="button" class="btn-pinktbl">{{ 'BUTTON_TYPES.USER_DETAIL' | translate }}</button>
                  </td>
                  <td class="tdata-4" *ngIf="history_button && History_Flg != '0'">
                    <button *ngIf="list.use_status != '1'" (click)="userhistory(list.casio_id, list.name)" type="button" class="btn-pinktbl">{{ 'BUTTON_TYPES.USER_HISTORY' | translate }}</button>
                  </td>
                  <td class="tdata-4">{{list.use_count}}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <label class="pages" *ngIf="filteredItems.length >= 1">{{ 'COMMON.PAGE' | translate }} {{ currentIndex }}/{{ pageNumber }}</label>
          <div class="page-point" *ngIf="filteredItems.length >= 1">
            <span
              [ngClass]="{ disabled: currentIndex == 1 || pageNumber == 0 }"
              (click)="prevPage()"
              class="prev"
              >&laquo;</span
            >
            <span
              *ngFor="let page of pagesIndex"
              [ngClass]="{ tod: currentIndex == page }"
              class="dot"
              (click)="setPage(page)"
            ></span>
            <span
              [ngClass]="{
                disabled: currentIndex == pageNumber || pageNumber == 0
              }"
              (click)="nextPage()"
              class="next"
            >&raquo;
            </span>
          </div>
          <div class="text-center" style="margin-top: 16px;max-width: 1500px;">
            <div class="col-12">
              <div class="display">
                <a (click)="csvOutput()" class="btn-search btn-csv csv-font">
                  {{'COMMON.CSV' | translate}}
                </a>
                <i class="fa fa-print"></i>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    
    <!-- BUTTON -->
    <div class="text1 text-center">
      <p id = "errormsg"> </p>
      <!-- <a (click)="info()" class="btn btn-primary btn-lg round">
        <i></i> 認証コードー括送信
      </a> -->
    </div>
    <!-- BUTTON end -->
  </section>
</div>

<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#E10800 " type = "square-jelly-box" [fullScreen] = "true">
  <p style="color: #E10800 " > {{ 'COMMON.LOADING' | translate }} </p>>
</ngx-spinner>