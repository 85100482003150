import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DatepickerOptions } from 'ng2-datepicker';
import { NgxSpinnerService } from 'ngx-spinner';
import { console_log, DataProvider } from 'src/providers/data/data';
import { CallapiService } from 'src/providers/rest/callapi.service';
import { getYear } from 'date-fns';
import locale from 'date-fns/locale/ja';
import { DatePipe } from '@angular/common';
import { IDatePickerConfig } from 'ng2-date-picker';
import moment from 'moment';

@Component({
  selector: 'app-list-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss'],
})
export class ListHistoryComponent implements OnInit {
  public Casio_ID = '';
  public Start_Date;
  public Use_Count = '';
  public Contractor_Name = '';
  filteredItems = [];
  pages: number;
  pageSize: number = 10;
  pageNumber: number = 0;
  currentIndex: number = 1;
  items = [];
  pagesIndex: Array<number>;
  pageStart: number = 1;
  public Today;
  constructor(
    private router: Router,
    public data: DataProvider,
    public rest: CallapiService,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    protected datepipe: DatePipe) {}

  ngOnInit() {
    this.data.menuPageTitle = 'contract-user-list';
    this.Casio_ID = this.route.snapshot.paramMap.get('casio_id');
    this.Contractor_Name = this.route.snapshot.paramMap.get('name');
    var x = new Date();
    x.setDate(1);
    x.setMonth(x.getMonth()-1);
    var newDate = moment(x).subtract(0, 'days');
    this.Start_Date = newDate;
    const body = {
      data:{
        user_uuid: this.data.uuid,
        casio_id: this.Casio_ID,
        start_date: this.datepipe.transform(this.Start_Date, 'yyyy-MM' + '-01')
      },
    };
    console_log(body)
    this.spinner.show();
    this.rest.Get_UseHistoryData(this.data.uuid, this.Casio_ID, this.datepipe.transform(this.Start_Date, 'yyyy/MM' + '/01')).subscribe(
      (res)=>{
        this.filteredItems = res.data;
        this.Use_Count = res.recordCount;
        console_log(res);
        if (res.code == 'WG00101') {
          document.getElementById('WG00101').style.display = 'block';
        } else {
          document.getElementById('WG00101').style.display = 'none';
        }
        this.currentIndex = 1;
        this.pageStart = 1;
        this.pages = 6;

        if(res.data) {
          this.filteredItems = res.data;
          this.pageNumber = parseInt('' + this.filteredItems.length / this.pageSize);
          if (this.filteredItems.length % this.pageSize != 0) {
            this.pageNumber++;
          }
          if (this.pageNumber < this.pages) {
            this.pages = this.pageNumber;
          }
        }
        this.spinner.hide();
        this.refreshItems();
      },
      (err)=>{
        this.spinner.hide();
        this.router.navigate(['/contract-error',{ code: err.error.error.code}]);
      }
    );
  }

  back(){
    this.router.navigate(['/contract-user-list']);
  }
  refreshItems() {
    this.items = this.filteredItems.slice(
      (this.currentIndex - 1) * this.pageSize,
      this.currentIndex * this.pageSize
    );
    this.pagesIndex = this.fillArray();
  }
  fillArray(): any {
    var obj = new Array();
    for (
      var index = this.pageStart;
      index < this.pageStart + this.pages;
      index++
    ) {
      obj.push(index);
    }
    return obj;
  }
  prevPage() {
    if (this.currentIndex > 1) {
      this.currentIndex--;
    }
    if (this.currentIndex < this.pageStart) {
      this.pageStart = this.currentIndex;
    }
    this.refreshItems();
  }

  nextPage() {
    if (this.currentIndex < this.pageNumber) {
      this.currentIndex++;
    }
    if (this.currentIndex >= this.pageStart + this.pages) {
      this.pageStart = this.currentIndex - this.pages + 1;
    }
    this.refreshItems();
  }
  selectedRadios(id) {
    this.pageSize = id;
    this.currentIndex = 1;
    this.pageStart = 1;
    this.pages = 6;

    this.pageNumber = parseInt('' + this.filteredItems.length / this.pageSize);
    if (this.filteredItems.length % this.pageSize != 0) {
      this.pageNumber++;
    }

    if (this.pageNumber < this.pages) {
      this.pages = this.pageNumber;
    }
    this.refreshItems();
  }
  setPage(index: number) {
    this.currentIndex = index;
    this.refreshItems();
  }
  Search(){
    const body = {
      data:{
        user_uuid: this.data.uuid,
        user_code: this.Casio_ID,
        start_date: this.datepipe.transform(this.Start_Date, 'yyyy-MM' + '-01')
      },
    };
    console_log(body);
    this.spinner.show();
    this.rest.Get_UseHistoryData(this.data.uuid, this.Casio_ID, this.datepipe.transform(this.Start_Date, 'yyyy/MM' + '/01')).subscribe(
      (res)=>{
        this.filteredItems = res.data;
        this.Use_Count = res.recordCount;
        console_log(res);
        if (res.code == 'WG00101') {
          document.getElementById('WG00101').style.display = 'block';
        } else {
          document.getElementById('WG00101').style.display = 'none';
        }
        this.currentIndex = 1;
        this.pageStart = 1;
        this.pages = 6;

        this.pageNumber = parseInt('' + this.filteredItems.length / this.pageSize);
        if (this.filteredItems.length % this.pageSize != 0) {
          this.pageNumber++;
        }
        if (this.pageNumber < this.pages) {
          this.pages = this.pageNumber;
        }
        this.refreshItems();
        this.spinner.hide();
      },
      (err)=>{
        this.spinner.hide();
        this.router.navigate(['/contract-error',{ code: err.error.error.code}]);
      }
    );
  }
  config: IDatePickerConfig = {
    format: 'yyyy-MM',
    yearFormat: 'yyyy'+'年',
    disableKeypress: true,
    onOpenDelay: 0,
    closeOnSelect: true,
    monthFormat: 'MM',
    monthBtnFormat: 'MM' + '月',
  }
  GoToCurrent(event){
    event = moment().subtract(0, 'days');
    this.Start_Date = event;
  }
}

