import { Component, OnDestroy, OnInit, VERSION } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'adminlte';
  data: any;

  constructor(
    public translate: TranslateService,
    private http: HttpClient,
    private router: Router
  ) {}

  ngOnInit() {
    this.translate.addLangs(['English', 'Japanese']);
    this.translate.setDefaultLang('Japanese');
    const browserLang = this.translate.getBrowserLang();

    this.translate.use(browserLang.match(/English|Japanese/) ? browserLang : 'Japanese')
      .pipe(
        catchError((error) => {
          console.log(error);
          window.history.replaceState('', '/contract-login', '/network-error');
          this.router.navigate(['/network-error']);
          return of(null); 
        })
      )
      .subscribe(() => { });
  }

  ngOnDestroy() {
    // this.router.navigate(['/network-error']);
  }
}
