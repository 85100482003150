import { Component, OnInit, Renderer2, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from 'src/app/utils/services/app.service';
import { environment } from 'src/environments/environment';
import { DataProvider } from 'src/providers/data/data';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent implements OnInit, OnDestroy {
  public changePasswordForm: FormGroup;
  public email;
  public password;
  public password2;
  public retypePassword;
  public logoUrl;

  constructor(
    private renderer: Renderer2,
    private appService: AppService,
    public data: DataProvider,
    public router: Router,
    private actRoute: ActivatedRoute,
    public translate: TranslateService,
  ) {}

  ngOnInit() {
    this.renderer.addClass(document.querySelector('app-root'), 'change-password-page');
    this.changePasswordForm = new FormGroup({
      password: new FormControl(null, Validators.required),
      password2: new FormControl(null, Validators.required),
      retypePassword: new FormControl(null, Validators.required),
    });
    this.email = this.actRoute.snapshot.params.email;
    this.logoUrl = environment.production ? 'assets/img/joyfit_logo.svg' : 'assets/img/joyfit_logo.svg';
  }

  onChange() {
    document.getElementById('errormsgchgpass').innerHTML = "";
  }

  changePassword() {
    if (this.changePasswordForm.valid) {
      if(this.password == '') {
        this.translate.get('CHANGE_PASSWORD.INSERT_THE_CODE').subscribe(
          (res) => (document.getElementById('errormsg').innerHTML = res)
        );
        return;
      }
      if(this.password2 != this.retypePassword) {
        this.translate.get('CHANGE_PASSWORD.NEW_PASS_IS_NOT_EQUAL_RETYPE_PASS').subscribe(
          (res) => (document.getElementById('errormsg').innerHTML = res)
        );
        return;
      }
      this.appService.forgotPasswordSubmit(this.email, this.password, this.password2).then(res2 => {
        this.appService.login(this.email, this.password2).then(res => {
          if(res !== undefined) {
            this.data.uuid = res.attributes['sub'];
            this.data.loggedEmail = this.email;
            this.data.loggedPassword = this.password2;
            this.router.navigate(['/']);
          }
        });
      }, err => {
        this.translate.get('CHANGE_PASSWORD.INSERT_THE_CORRECT_NEW_PASS').subscribe(
          (res) => (document.getElementById('errormsg').innerHTML = res)
        );
      });
    } else {
      this.translate.get('CHANGE_PASSWORD.FAILED_TO_CHANGE').subscribe(
        (res) => (document.getElementById('errormsg').innerHTML = res)
      );
    }
  }

  ngOnDestroy() {
    this.renderer.removeClass(
      document.querySelector('app-root'),
      'change-password-page'
    );
  }
}
