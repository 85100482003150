<div class="main-padding">
  <h3 class="header-bottom">{{ 'ALL_VERIFICATION_CODE_SEND.TITLE_TEXT' | translate }}</h3>
  <section class="content">
    <div class="title_margintop">
      <h3 class="header-text1">{{ 'AUTHMAIL_ERROR.TEXT_1' | translate }}
      <br>{{ 'AUTHMAIL_ERROR.TEXT_2' | translate }}</h3>
    </div>
    <section class="content"> 
      <div class="container-fluid" style="color: black;">
        <div class="row table-width">
          <table id="example2" class="table table-bordered" style="background-color: white;min-width: 600px">
            <thead>
              <tr>
                <th>{{ 'REGISTRATION_LIST.CONTRACTOR_NAME' | translate }}</th>
                <th>{{ 'COMMON_WORDS.MAIL_ADDRESS' | translate }}</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let list of ErrorData">
                <td class="tdata-1">{{list.last_name}}{{list.first_name}}</td>
                <td class="tdata-2">{{list.mail_address}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </section>
    <div class="text-center" style="margin-top: 40px;">
      <a (click)="back()" class="btn-grey">
        <i></i> {{ 'BUTTON_TYPES.BACK' | translate }}
      </a>
    </div>
    <!-- BUTTON end -->
  </section>

</div>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#E10800" type="square-jelly-box" [fullScreen]="true">
  <p style="color:#E10800"> 
    {{'COMMON.LOADING' | translate }}</p>
</ngx-spinner>