import { Component, OnInit, Renderer2 } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AppService } from 'src/app/utils/services/app.service';
import { Router } from '@angular/router';
import { DataProvider } from 'src/providers/data/data';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
})
export class MainhomeComponent implements OnInit {
  constructor(
    private renderer: Renderer2,
    private appService: AppService,
    private router: Router,
    public data: DataProvider
  ) {}
  public Corporation_Form;

  ngOnInit() {
    this.data.menuPageTitle = 'contract-home';
    this.Corporation_Form = JSON.parse(localStorage.getItem('loginData')).data.corporation_form;
  }
  info() {
    document.getElementById('infoedit').style.display='none';
    document.getElementById('info').style.display='block';
  }
}
