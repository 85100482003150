<div class="main-padding">
  <h3 class="header-bottom">{{'SIDEBAR_MENU.MAIL_ADDRESS' | translate }}</h3>
  <div class="card-body">
    <p class="left-border">{{'MAIL_ADDRESS.CHANGE_MAIL_ADDRESS' | translate }}</p>
    <p class ="card-title">{{'MAIL_ADDRESS.SEND_YOU_EMAIL_WITHURL_MSG' | translate }}<br> {{ 'MAIL_ADDRESS.ENTER_EMAIL_PRESS_SEND_MSG' | translate }}</p>
  </div>
  <div class="text-center">
      <div (ngSubmit)="change()" class="login-box" style="margin: auto;">
        <div class="input-group mb-3">
          <div class="input-group-append">
            <div class="input-group-text">
              <span class="whiteI-3 nav-icon ls"></span>
            </div>
          </div>
          <input
            type="email"
            class="form-control"
            name="New_Mail" [(ngModel)]="New_Mail"
            placeholder="{{ 'COMMON_WORDS.MAIL_ADDRESS' | translate }}"
          />
        </div>
        <div class="text-center">
          <div class="col-12">
            <div class="text1 text-center">
              <p id="error" style="height: 12px"></p>
            </div>
          </div>
          <div class="col-12">
            <div class="display" style="margin-top: 10px;">
              <a (click)="change()" class="btn-log">
                <i></i> {{ 'BUTTON_TYPES.SEND' | translate }}
              </a>
            </div>
          </div>
        </div>
      </div>
  </div>
</div>
<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#E10800 " type = "square-jelly-box" [fullScreen] = "true">
  <p style="color: #E10800 " > 
    {{ 'COMMON.LOADING' | translate }}</p>>
</ngx-spinner>