import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { MainComponent } from './pages/main/main.component';
import { LoginComponent } from './pages/login/login.component';
import { MailCompleteComponent } from './pages/mail-comp/mail-comp.component';
import { ProfileComponent } from './views/profile/profile.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { AuthGuard } from './utils/guards/auth.guard';
import { NonAuthGuard } from './utils/guards/non-auth.guard';
import { ChangePasswordComponent } from './pages/change-password/change-password.component';

import { HomeComponent } from './views/1-contract-home/home.component';
import { CorpReferenceComponent } from './views/2-contract-corp/corp-reference.component';
import { MailReferenceComponent } from './views/3-contract-mail/reference/mail-reference.component';
import { MailEditComponent } from './views/3-contract-mail/edit/mail-edit.component';
import { MailSendedComponent } from './views/3-contract-mail/sended/mail-sended.component';
import { RegisterComponent } from './views/4-contract-user-register/register/register.component';
import { RegisterCompleteComponent } from './views/4-contract-user-register/complete/complete.component';
import { AuthmailConfigureComponent } from './views/4-contract-user-register/authmail-configure/configure.component';
import { BulkRegisterComponent } from './views/5-user-bulk-register/bulk-register.component';
import { BulkRegisterFailComponent  } from './views/5-user-bulk-register/bulk-regist-fail/bulk-regist-fail.component';
import { BulkRegisterFailListComponent  } from './views/5-user-bulk-register/bulk-regist-fail-list/bulk-regist-fail-list.component';
import { BulkRegisterErrorComponent  } from './views/5-user-bulk-register/bulk-regist-error/bulk-regist-error.component';


// import { RegisterComponent } from './views/4-contract-user-register/register/register.component';

import { ListComponent } from './views/6-contract-user-list/list/list.component';
import { ListDetailComponent } from './views/6-contract-user-list/detail/detail.component';
import { ListEditComponent } from './views/6-contract-user-list/edit/edit.component';
import { ListDeleteComponent } from './views/6-contract-user-list/delete/delete.component';
import { ListHistoryComponent } from './views/6-contract-user-list/history/history.component';
import { BulkAuthmailComponent } from './views/7-contract-bulk-authmail/authmail/authmail.component';
import { BulkAuthmailCompleteComponent } from './views/7-contract-bulk-authmail/authmail-complete/authmail-complete.component';
import { BulkAuthmailErrorComponent } from './views/7-contract-bulk-authmail/authmail-error/error.component';
import { WarningComponent } from './views/warning/warning.component';
import { MainhomeComponent } from './views/main/main.component';
import { ErrorComponent } from './pages/error/error.component';
import { ViewsErrorComponent } from './views/error/error.component';
import { MailErrorComponent } from './pages/mail-err/mail-err.component';
import { CorpEditComponent } from './views/2-contract-corp/corp-edit/corp-edit.component';
import { UnpaidPaymentComponent } from './views/8-contract-unpaid-payment/unpaid-payment.component';
import { UnpaidPaymentConfirmComponent } from './views/8-contract-unpaid-payment/unpaid-payment-conf/unpaid-payment-conf.component';
import { UnpaidPaymentCompleteComponent } from './views/8-contract-unpaid-payment/unpaid-payment-comp/unpaid-payment-comp.component';
import { BulkRegisterCompComponent } from './views/5-user-bulk-register/bulk-regist-comp/bulk-regist-comp.component';
import { UnpaidPaymentErrorComponent } from './views/8-contract-unpaid-payment/unpaid-payment-err/unpaid-payment-err.component';
import { UnpaidPaymentCancelComponent } from './views/8-contract-unpaid-payment/unpaid-payment-cancel/unpaid-payment-cancel.component';
import { ErrorNetworkComponent } from './pages/networkError/error.component';

const routes: Routes = [
  {
    path: '',
    component: MainComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      {
        path: 'main',
        component: MainhomeComponent,
      },
      {
        path: 'contract-corp-reference',
        component: CorpReferenceComponent,
      },
      {
        path: 'contract-corp-edit',
        component: CorpEditComponent,
      },
      {
        path: 'contract-mail-reference',
        component: MailReferenceComponent,
      },
      {
        path: 'contract-mail-edit',
        component: MailEditComponent,
      },
      {
        path: 'contract-mail-sended',
        component: MailSendedComponent,
      },
      {
        path: 'contract-user-regist',
        component: RegisterComponent,
      },
      {
        path: 'contract-user-regist-comp',
        component: RegisterCompleteComponent,
      },
      {
        path: 'contract-user-regist-authmail-conf',
        component: AuthmailConfigureComponent,
      },
      {
        path: 'contract-user-bulk-regist',
        component: BulkRegisterComponent,
      },
      {
        path: 'contract-user-bulk-regist-comp',
        component: BulkRegisterCompComponent,
      },
      {
        path: 'contract-user-bulk-regist-fail',
        component: BulkRegisterFailComponent ,
      },
      {
        path: 'contract-user-bulk-regist-fail-list',
        component: BulkRegisterFailListComponent  ,
      },
      {
        path: 'contract-user-bulk-regist-error',
        component: BulkRegisterErrorComponent  ,
      },
      {
        path: 'warning',
        component: WarningComponent,
      },
      {
        path: 'contract-user-list',
        component: ListComponent,
      },
      {
        path: 'contract-user-list-detail',
        component: ListDetailComponent,
      },
      {
        path: 'contract-user-list-edit',
        component: ListEditComponent,
      },
      {
        path: 'contract-user-list-delete',
        component: ListDeleteComponent,
      },
      {
        path: 'contract-user-list-history',
        component: ListHistoryComponent,
      },
      {
        path: 'contract-bulk-authmail',
        component: BulkAuthmailComponent,
      },
      {
        path: 'contract-bulk-authmail-comp',
        component: BulkAuthmailCompleteComponent,
      },
      {
        path: 'contract-bulk-authmail-error',
        component: BulkAuthmailErrorComponent,
      },
      {
        path: 'contract-err',
        component: ViewsErrorComponent,
      },
      {
        path: 'profile',
        component: ProfileComponent,
      },
      // 8-contract-unpaid-payment
      {
        path: 'contract-unpaid-payment',
        component: UnpaidPaymentComponent,
      },
      {
        path: 'contract-unpaid-payment-conf',
        component: UnpaidPaymentConfirmComponent,
      },
      {
        path: 'contract-unpaid-payment-comp',
        component: UnpaidPaymentCompleteComponent,
      },
      {
        path: 'contract-unpaid-payment-err',
        component: UnpaidPaymentErrorComponent,
      },

      {
        path: 'contract-unpaid-payment-cancel',
        component: UnpaidPaymentCancelComponent,
      },
      {
        path: '',
        component: HomeComponent,
      },
    ],
  },
  {
    path: 'contract-login',
    component: LoginComponent,
    canActivate: [NonAuthGuard],
  },
  {
    path: 'contract-forgot-pw',
    component: ResetPasswordComponent,
    canActivate: [NonAuthGuard],
  },
  {
    path: 'contract-change-pw',
    component: ChangePasswordComponent,
    canActivate: [NonAuthGuard],
  },
  {
    path: 'contract-mail-comp',
    component: MailCompleteComponent,
    canActivate: [NonAuthGuard],
  },
  {
    path: 'contract-mail-err',
    component: MailErrorComponent,
    canActivate: [NonAuthGuard],
  },
  {
    path: 'contract-error',
    component: ErrorComponent,
    canActivate: [NonAuthGuard],
  },
  {
    path: 'network-error',
    component: ErrorNetworkComponent,
    canActivate: [NonAuthGuard],
  },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy',useHash: true, })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
