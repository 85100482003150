import { Component, OnInit, Renderer2, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AppService } from 'src/app/utils/services/app.service';
import { environment } from 'src/environments/environment';
import { console_log, DataProvider } from 'src/providers/data/data';
import { CallapiService } from 'src/providers/rest/callapi.service';

@Component({
  selector: 'app-change-mail-address',
  templateUrl: './mail-comp.component.html',
  styleUrls: ['./mail-comp.component.scss'],
})
export class MailCompleteComponent implements OnInit, OnDestroy {
  public logoUrl;
  public crypto_uuid = '';
  public crypto_pattern;
  constructor(
    private renderer: Renderer2,
    private appService: AppService,
    public data: DataProvider,
    public router: Router,
    private spinner: NgxSpinnerService,
    public rest: CallapiService,
    private route: ActivatedRoute,
  ) {}

  ngOnInit() {
    this.spinner.show();
    this.route.queryParams.subscribe(params => {
      this.crypto_uuid = params['id'];
      this.crypto_pattern = params['cpn'];
      this.renderer.addClass(document.querySelector('app-root'), 'email-confirm-page');
      this.logoUrl = environment.production ? 'assets/img/joyfit_logo.svg' : 'assets/img/joyfit_logo.svg';
      const body = {
        data:{
          crypto_uuid: this.crypto_uuid,
          crypto_pattern: this.crypto_pattern,
        },
      };
      console_log(body);
      localStorage.setItem('token',this.crypto_uuid);
      this.rest.Update_CognitoMail(this.crypto_uuid, this.crypto_pattern).subscribe(
        (res) => {
          console_log(res);
          this.spinner.hide();
        },
        (err) => {
          console_log(err);
          this.spinner.hide();
          this.router.navigate(['/contract-mail-err', {error_code: err.error.error.code}]);
        }
      );
    });    
  }

  onChange() {
    document.getElementById('errormsgchgpass').innerHTML = "";
  }

  ngOnDestroy() {
    this.renderer.removeClass(
      document.querySelector('app-root'),
      'email-confirm-page'
    );
  }
}
