<div class="main-padding">
  <!-- Section 1 -->
  <section class="content" id="info">
    <!-- 1 -->
    <div class="card-body">
      <div class="row">
        <p class="subtitleText">{{ 'HOME2.NOTICE' | translate }}</p>
      </div>
      <div class="row">
        <textarea id="txtarea1" name="w3review" rows="4">2021/03/08　10:00よりメンテナンスの予定。
        </textarea>
      </div>
    </div>
    <!-- 2 -->
    <div class="card-body">
      <div class="row">
        <button class="btn-pinkmini"><img src="assets/icons/book.svg"/> {{ 'HOME2.MANUAL' | translate }}</button>
      </div>
      <div class="row">
        <p class="subtitleText" style="margin-top: 22px;">{{ 'HOME2.CONTRACT_DETAIL' | translate }}</p>
      </div>
      <div class="row">
          <textarea id="txtarea2" name="w3review" rows="4" *ngIf = "Corporation_Form == '1' || Corporation_Form == '2'">
            契約形態 
            ◯◯◯◯◯◯◯◯◯◯◯◯◯◯ 
            
            プロモーションコード 
            ◯◯◯人
          </textarea>
          <textarea id="txtarea2" name="w3review" rows="4" *ngIf = "Corporation_Form == '3'">
            契約形態
            〇〇〇〇〇〇〇〇〇〇〇〇
            プロモーションコード①(月会費：6,578円,利用可能店舗：JOYFIT,JOYFIT24)
            〇〇〇〇〇
            プロモーションコード②(月会費：9,878円,利用可能店舗：JOYFIT,JOYFIT24,JOYFIT YOGA)
            〇〇〇〇〇
          </textarea>
      </div>
    </div>
  </section>
</div>