<div class="main-padding">
  <!-- <div class="scroll-thumb" style="overflow-x: scroll;overflow-y: hidden;z-index: 1000;bottom:0;"></div> -->
  <h3 class="header-bottom">{{ 'SIDEBAR_MENU.COMPANY_INFO' | translate }}</h3>

 
  <!-- Section 2 -->
  <section class="content">
    <div class="card-body">
      <p class="left-border">{{'COMPANY_INFO.CHANGER_COMPANY_INFO' | translate }}</p>
      <p class="card-title">{{'COMMON_NOTICE.PLEASE_INSERT_CHANGE_POINT' | translate }}</p>
    </div>
    <!-- 1 -->
    <div class="card-body">
      <div class="row detail1">
        <div class="col-12 col-sm-6	col-md-6 col-lg-6 col-xl-6 col-dest">
          <h3 class="card-title"> {{'DETAILS.COMPANY_NUMBER_DEST' | translate }}</h3>
        </div>
        <div class="col-12 col-sm-6	col-md-6 col-lg-6 col-xl-6 col-dest2">
          <div class="col-12">
            <div class="col-12 col-sm-8	col-md-8 col-lg-8 col-xl-8">
              <h3 class="card-title"> {{ Corporation_No }}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card-body"  *ngIf="Corporation_Form == '1' || Corporation_Form == '2'">
      <div class="row detail1">
        <div class="col-12 col-sm-6	col-md-6 col-lg-6 col-xl-6 col-dest">
          <h3 class="card-title"> {{'DETAILS.PROMO_CODE' | translate }}</h3>
        </div>
        <div class="col-12 col-sm-6	col-md-6 col-lg-6 col-xl-6 col-dest2">
          <div class="col-12">
            <div class="col-12 col-sm-8	col-md-8 col-lg-8 col-xl-8"> 
              <h3 class="card-title">
                {{Promo_Code1}}
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card-body"  *ngIf="Corporation_Form == '3'">
      <div class="row detail1 mb-3">
        <div class="col-12 col-sm-6    col-md-6 col-lg-6 col-xl-6 col-dest">
          <h3 class="card-title"> {{'DETAILS.PROMO_CODE' | translate }} ①</h3>
        </div>
        <div class="col-12 col-sm-6    col-md-6 col-lg-6 col-xl-6 col-dest2">
          <div class="col-12">
            <div class="col-12 col-sm-8    col-md-8 col-lg-8 col-xl-8">
              <h3 class="card-title">
                {{Promo_Code1}}
              </h3>
            </div>
          </div>
        </div>
      </div>
      <div class="row detail1">
        <div class="col-12 col-sm-6    col-md-6 col-lg-6 col-xl-6 col-dest">
          <h3 class="card-title"> {{'DETAILS.PROMO_CODE' | translate }} ② </h3>
        </div>
        <div class="col-12 col-sm-6    col-md-6 col-lg-6 col-xl-6 col-dest2">
          <div class="col-12">
            <div class="col-12 col-sm-8    col-md-8 col-lg-8 col-xl-8">
              <h3 class="card-title">
                {{Promo_Code2}}
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 2 -->
    <div class="card-body">
      <div class="row detail1">
        <div class="col-12 col-sm-4	col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title">{{'DETAILS.COMPANY_NAME_DEST' | translate }}</h3>
        </div>
        <div class="col-12 col-sm-4	col-md-4 col-lg-4 col-xl-4 col-dest2">
          <div class="col-12">
            <div class="col-12 col-sm-8	col-md-8 col-lg-8 col-xl-8">
              <h3 class="card-title">{{ CompanyName }}<br>{{CompanyNameKana}}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 3 -->
    <div class="card-body">
      <div class="row detail1">
        <div class="col-12 col-sm-4	col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title">{{'DETAILS.ADDRESS_DEST' | translate }}</h3>
        </div>
        <div class="col-12 col-sm-8	col-md-8 col-lg-8 col-xl-8 col-dest2">
          <div class="col-12">
            <div class="col-12 col-sm-12	col-md-8 col-lg-8 col-xl-8">
              <h3 class="card-title">{{PostCode}}<br>{{Address1}}<br>{{Address2}}<br>{{Address3}}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 4 -->
    <div class="card-body">
      <div class="row detail1">
        <div class="col-12 col-sm-4	col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title">{{'DETAILS.TELEPHONE_DEST' | translate }}</h3>
        </div>
        <div class="col-12 col-sm-4	col-md-4 col-lg-4 col-xl-4 col-dest2">
          <div class="col-12">

            <div class="col-12 col-sm-8	col-md-8 col-lg-8 col-xl-8">
              <h3 class="card-title">{{ Telephone }}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 4 -->
    <div class="card-body" *ngIf="Corporation_Form != '3'">
      <div class="row detail1">
        <div class="col-12 col-sm-4	col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title">{{'DETAILS.INSURANCE_CARD' | translate }}</h3>
        </div>
        <div class="col-12 col-sm-4	col-md-4 col-lg-4 col-xl-4 col-dest2">
          <div class="col-12">

            <div class="col-12 col-sm-8	col-md-8 col-lg-8 col-xl-8">
            <h3 class="card-title">{{ health_code }}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12 col-md-11 col-xl-11 display">
      <p class="text1">{{ 'COMPANY_INFO.CONTACT_WELLNESS_FROTIER_CORPORATE_MSG' | translate }}</p>
    </div>

      <!-- 5 -->
      <div class="card-body">
        <div class="row detail1">
          <div class="col-12 col-sm-3	col-md-3 col-lg-3 col-xl-3 col-dest">
            <h3 class="card-title"> {{ 'DETAILS.CHARGER_DEST' | translate }}</h3>
          </div>
          <div class="col-12 col-sm-8	col-md-8 col-lg-8 col-xl-8 col-dest2">
            <div class="col-12">
              <span class="badge badge-info">{{ 'COMMON.COMMON_REQUIRE_LABEL_1' | translate }}</span>
              <div class="custom-control display col-12 col-sm-5	col-md-5 col-lg-5 col-xl-5">
                <input 
                  type="text" 
                  class="form-control names" 
                  placeholder="鈴木" 
                  name="Last_Name"
                  [(ngModel)]="Last_Name"
                />
              </div>
              <div class="display col-12 col-sm-5	col-md-5 col-lg-5 col-xl-5">
                <input type="text" class="form-control names1" placeholder="太郎" name="Name" [(ngModel)]="Name">
              </div>
            </div>
            <div class="col-12" style="margin-top: 10px;">
              <span class="badge badge-info">{{ 'COMMON.COMMON_REQUIRE_LABEL_1' | translate }}</span>
              <div class="custom-control display col-12 col-sm-5	col-md-5 col-lg-5 col-xl-5">
                <input type="text" class="form-control names" placeholder="スズキ" name="Last_Name_Kana" [(ngModel)]="Last_Name_Kana" (ngModelChange)="onKeyLastNameKana(Last_Name_Kana)">
              </div>
              <div class="display col-12 col-sm-5	col-md-5 col-lg-5 col-xl-5">
                <input type="text" class="form-control names1" placeholder="タロウ" name="Name_Kana" [(ngModel)]="Name_Kana"  (ngModelChange)="onKeyNameKana(Name_Kana)">
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 6 -->
      <div class="card-body">
        <div class="row detail1">
          <div class="col-12 col-sm-3	col-md-3 col-lg-3 col-xl-3 col-dest">
            <h3 class="card-title"> {{'DETAILS.DEPARTMENT_POSITION_DEST' | translate }}</h3>
          </div>
          <div class="col-12 col-sm-8	col-md-8 col-lg-8 col-xl-8 col-dest2">
            <div class="col-12">
              <span class="badge badge-secondary">{{ 'COMMON.COMMON_REQUIRE_LABEL_2' | translate }}</span>
              <div class="custom-control display col-12 col-sm-5	col-md-5 col-lg-5 col-xl-5">
                <input type="text" class="form-control names" placeholder="〇〇〇〇〇〇部" name="Staff_Post" [(ngModel)]="Staff_Post">
              </div>
              <div class="display col-12 col-sm-5	col-md-5 col-lg-5 col-xl-5">
                <input type="text" class="form-control names1" placeholder="〇〇〇〇" name="Staff_Position" [(ngModel)]="Staff_Position">
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 9 -->
      <div class="card-body">
        <div class="row detail1">
          <div class="col-12 col-sm-3	col-md-3 col-lg-3 col-xl-3 col-dest">
            <h3 class="card-title"> {{'COMPANY_INFO.NUMBER_OF_USERS_DEST' | translate }}</h3>
          </div>
          <div class="col-12 col-sm-8	col-md-8 col-lg-8 col-xl-8 col-dest2">
            <div class="col-12">
              <span class="badge badge-info">{{ 'COMMON.COMMON_REQUIRE_LABEL_1' | translate }}</span>
              <div class="custom-control display col-12 col-sm-5	col-md-5 col-lg-5 col-xl-5">
                <input
                  type="text"
                  class="form-control"
                  placeholder="15"
                  name="Users"
                  [(ngModel)]="Users"
                  maxlength="11"
                  onkeypress="return (event.charCode != 69 && event.charCode != 101) && (event.charCode < 42 && 8 <= event.charCode) || (event.charCode <= 57 && 47 < event.charCode)"
              />
              </div>
            </div>
          </div>
        </div>
      </div>

    <!-- 10 -->
    <div class="card-body">
      <div class="row detail1">
        <div class="col-12 col-sm-3	col-md-3 col-lg-3	col-xl-3 col-dest">
          <h3 class="card-title"> {{ 'DETAILS.BEFOREAFTER_DEST' | translate }}</h3>
        </div>
        <div class="col-12 col-sm-12	col-md-8 col-lg-8	col-xl-8 col-dest2 radio-row">
          <div class="col-12">
            <span class="badge badge-info">{{ 'COMMON.COMMON_REQUIRE_LABEL_1' | translate }}</span>
            <div class="column-check display">
              <div class="form-group">
                <div class="custom-control custom-radio" 
                style="margin-left: 25px;"
                (click)="selectHistory('1')"
                *ngIf="History_Flg == '1'">
                  <input
                    class="custom-control-input custom-control-input-danger custom-control-input-outline"
                    type="radio"
                    id="R1"
                    name="customRadio1"
                    checked
                  />
                  <label for="R1" class="custom-control-label card-title1">{{
                    'COMPANY_INFO.USE_USED_HISTORY' | translate
                  }}</label>
                </div>
                <div class="custom-control custom-radio" 
                style="margin-left: 25px;"
                (click)="selectHistory('1')"
                *ngIf="History_Flg != '1'">
                  <input
                    class="custom-control-input custom-control-input-danger custom-control-input-outline"
                    type="radio"
                    id="R1"
                    name="customRadio1"
                  />
                  <label for="R1" class="custom-control-label card-title1">{{
                    'COMPANY_INFO.USE_USED_HISTORY' | translate
                  }}</label>
                </div>
              </div>
            </div>
            <div class="column-check display">
              <div class="form-group">
                <div class="custom-control custom-radio"
                (click)="selectHistory('0')"
                *ngIf="History_Flg == '0'">
                  <input
                    class="custom-control-input custom-control-input-danger custom-control-input-outline"
                    type="radio"
                    id="R2"
                    name="customRadio1"
                    checked
                  />
                  <label for="R2" class="custom-control-label card-title1">{{
                    'COMPANY_INFO.DONT_USE_USED_HISTORY' | translate
                  }}</label>
                </div>
                <div class="custom-control custom-radio"
                (click)="selectHistory('0')"
                *ngIf="History_Flg != '0'">
                  <input
                    class="custom-control-input custom-control-input-danger custom-control-input-outline"
                    type="radio"
                    id="R2"
                    name="customRadio1"
                  />
                  <label for="R2" class="custom-control-label card-title1">{{
                    'COMPANY_INFO.DONT_USE_USED_HISTORY' | translate
                  }}</label>
                </div>

              </div>
            </div>
          </div>
          <div class="col-12">
            <p class="red-text">{{'COMPANY_INFO.USE_USAGE_HISTORY' | translate}} <br>{{'COMPANY_INFO.USE_USAGE_HISTORY1' | translate}} </p>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="card-body">
      <div class="row detail1">
        <div class="col-12 col-md-12 col-xl-12">
          <p class="card-result">{{ 'COMPANY_INFO.IF_CHANGE_REGISTER_ATTACHPDF_MSG' | translate }}</p>
        </div>
      </div>
    </div> -->
    <!-- 12 -->
    <!-- <div class="card-body">
      <div class="row detail1 pdf-row">
        <div class="col-12 col-sm-3	col-md-3 col-lg-3 col-xl-3 col-dest">
          <h3 class="card-title"> {{ 'COMPANY_INFO.REGISTRATION_COPY' | translate }}(PDF)</h3>
        </div>
        <div class="col-12 col-sm-8	col-md-8 col-lg-8 col-xl-8 col-dest2">
          <div class="col-12">
            <span class="badge badge-secondary">{{ 'COMMON.COMMON_REQUIRE_LABEL_2' | translate }}</span>
            <div class="col-8 custom-control display">
              <input
                type="text"
                class="form-control"
                placeholder="{{ 'COMMON_WORDS.NOT_SELECTED' | translate }}"
                name="pdffile_name"
                [(ngModel)]="pdffile_name"
                disabled
              />
            </div>
            <div class="custom-control display col-xl-3 col-md-5 col-10">
              <button
                class="form-control select-pdf"
                (click)="selectPDF()"
                style="width: 95px; font-size: 12px"
              >
                {{ 'BUTTON_TYPES.SELECT_FILE' | translate }}
              </button>
              <input
                type="file"
                accept="application/pdf"
                id="pdffile"
                (change)="upload($event)"
                style="display: none"
              />
            </div>
          </div>
        </div>
      </div>
    </div> -->

    <div class="card-body">
      <div class="row detail1">
        <div class="col-12 col-sm-3    col-md-3 col-lg-3 col-xl-3 col-dest">
          <h3 class="card-title"> {{'DETAILS.MEMBERSHIP_FEE_BURDEN' | translate }}</h3>
        </div>
        <div class="col-12 col-sm-8    col-md-8 col-lg-8 col-xl-8 col-dest2">
          <div class="col-12">
            <h3 class="card-title marginLeft" *ngIf="paid_form == '3'">
              {{ 'DETAILS.COMPANY_REGISTER_PERSONAL_BURDEN' | translate }}
            </h3>
            <h3 class="card-title marginLeft" *ngIf="paid_form == 2">
              {{ 'DETAILS.COMPANY_REGISTER_CORPORATE_BURDEN_GO' | translate }}
            </h3>
            <h3 class="card-title marginLeft" *ngIf="paid_form == '1'">
              {{ 'DETAILS.COMPANY_REGISTER_CORPORATE_BURDEN' | translate }}
            </h3>
          </div>
        </div>
      </div>
    </div>
    <!-- 13 -->
    <div class="card-body">
      <div class="row detail1">
        <div class="col-12 col-md-3 col-xl-3 col-dest">
          <h3 class="card-title" style="margin-top: 12px;">{{ 'COMPANY_INFO.CURRENT_REGISTRATION_COPY' | translate }}
          </h3>
        </div>
        <div class="col-12 col-md-5 col-xl-5 col-dest2">
          <div class="col-12 display">
            <div class="col-12 col-md-9 col-xl-9 display">
              <a class="btn-pdf" (click)="pdf_download()">
                <i class="nav-icon whiteI-file"></i> {{ 'BUTTON_TYPES.REGISTRATION_COPY_PDF' | translate }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- button -->
    <div class="text-center">
      <div class="text1 text-center" style="height: 34px;min-width: 874px;">
        <p style="display: none;" id="error"></p>
      </div>
      <a (click)="update()" class="btn-pink">
        <i></i> {{ 'BUTTON_TYPES.UPDATE' | translate }}
      </a>
    </div>
  </section>
</div>
