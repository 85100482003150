<div style="position: absolute;top: 0;left: 0;width: 100%;">
  <nav class="navbar navbar-expand navbar-white navbar-light" style="padding-left: 0px;margin-left: 0;">
    <a [routerLink]="['/contract-login']">
    <img
        src={{logoUrl}}
        alt="AdminLTE Logo"
        class="brand-image"
      /></a>
  </nav>
</div>
<div class="login-box1">
  <div class="login-logo">
    <i class='blackuser'></i>
    <p style="margin-top: -6px;" class="title-login">{{ 'CHANGE_PASSWORD.TITLE' | translate }}</p>
  </div>
  <div class="ftext text-center" style="padding-top: 24px;">
    <p>{{ 'CHANGE_PASSWORD.PASSWORD_RESET_TEXT' | translate }} <br>{{ 'CHANGE_PASSWORD.PASSWORD_RESET_TEXT1' | translate }}</p>
    <p></p>
    <p>{{ 'CHANGE_PASSWORD.PASSWORD_RESET_TEXT2' | translate }} <br> {{ 'CHANGE_PASSWORD.PASSWORD_RESET_TEXT3' | translate }}</p>
  </div>
</div>
<div class="login-box">
  <form [formGroup]="changePasswordForm" (ngSubmit)="changePassword()">
    <div class="input-group mb-3"  style="margin-top: 24px;">
      <div class="input-group-append">
        <div class="input-group-text" style="background-color: #444444;">
          <span class="ls key"></span>
        </div>
      </div>
      <input
        formControlName="password"
        type="text"
        class="form-control"
        placeholder="{{ 'CHANGE_PASSWORD.PASSWORD_TEMP_PLACEHOLDER' | translate }}"
        [(ngModel)]="password"
        (ngModelChange)="onChange()"
      />
    </div>
    <div class="input-group mb-3" style="margin-top: 40px;">
      <div class="input-group-append">
        <div class="input-group-text" style="background-color: #444444;">
          <span class="ls key"></span>
        </div>
      </div>
      <input
        formControlName="password2"
        type="password"
        class="form-control"
        placeholder="{{ 'CHANGE_PASSWORD.PASSWORD_CHANGED_PLACEHOLDER' | translate }}"
        [(ngModel)]="password2"
        (ngModelChange)="onChange()"
      />
    </div>
    <div class="input-group mb-3">
      <div class="input-group-append">
        <div class="input-group-text" style="background-color: #444444;">
          <span class="ls key"></span>
        </div>
      </div>
      <input
        formControlName="retypePassword"
        type="password"
        class="form-control"
        placeholder="{{ 'CHANGE_PASSWORD.PASSWORD_CHANGED_CONFIRM_PLACEHOLDER' | translate }}"
        [(ngModel)]="retypePassword"
        (ngModelChange)="onChange()"
      />
    </div>
    <div class="text-center">
      <div class="text1 text-center" >
        <p id="errormsg"></p>
      </div>
      <div class="display" style="margin-top: 6px">
        <a (click)="changePassword()" class="btn-log"> {{ 'CHANGE_PASSWORD.PASSWORD_CHANGE_BUTTON' | translate }}</a>
      </div>
    </div>
  </form>
</div>
