<div class="main-padding">
  <h3 class="header-bottom">
    {{ 'SIDEBAR_MENU.REGISTER_ALL_USER' | translate }}
  </h3>
  <!-- Section №1 -->
  <section class="content">
    <div class="card-body">
      <p class="left-border">
        {{ 'REGISTER_ALL_USER.REGISTER_ALL_USER' | translate }}
      </p>
      <p class="card-title">
        {{ 'REGISTER_ALL_USER.SELECT_CSVFILE_PRESS_UPLOAD_MSG' | translate }}
        <br />
        {{ 'REGISTER_ALL_USER.IF_ERROR_FILECONTENT_BATCH_FAIL_MSG' | translate }}
        <br />
        {{ 'REGISTER_ALL_USER.MAY_TAKE_SOME_TIME_COMPLETEREG_MSG' | translate }}
      </p>
    </div>
    <!-- 1 -->
    <div class="card-body">
      <div class="row detail1">
        <div class="col-12 col-sm-3	col-md-3 col-lg-3 col-xl-3 col-dest">
          <p class="card-title"> 
            {{ 'REGISTER_ALL_USER.USER_LIST' | translate }}
          </p>
        </div>
        <div class="col-12 col-sm-8	col-md-8 col-lg-8 col-xl-8 col-dest2">
          <div class="col-12">
            <span class="badge badge-info">
              {{ 'COMMON.COMMON_REQUIRE_LABEL_1' | translate }}
            </span>
            <div class="custom-control col-12 col-sm-5col-md-5 col-lg-5 col-xl-5" style="display: -webkit-inline-box;">
              <button class="btn-upload" (click)="selectCSV()">
                {{ 'BUTTON_TYPES.SELECT_FILE' | translate }}
              </button>
              <input
                type="file"
                accept="*"
                id="csvfile"
                (change)="uploadCSV($event)"
                style="display: none"
              />
              <p class="csvfile" id="csvfile_name">
                {{ 'COMMON_WORDS.NOT_SELECTED' | translate }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- BUTTON -->
    <div class="text-center" style="height: 34px;">
      <div class="text1">
        <p id="error" style="display: none"></p>
      </div>
    </div>
    <div class="text-center">
      <div class="col-12 two-btn">
        <div class="display">
          <a (click)="reset()" class="btn-grey">
            {{ 'BUTTON_TYPES.CANCEL' | translate }}
          </a>
        </div>
        <div class="display">
          <a (click)="upload()" class="btn-pink">
            {{ 'BUTTON_TYPES.UPLOAD' | translate }}
          </a>
        </div>
      </div>
    </div>
    <!-- BUTTON end -->
    <div class="card-body">
      <div class="text-center">
        <p class="card-result">
          <a class="csv-url" >
            {{ 'REGISTER_ALL_USER.URL_TEXT_DOWNLOAD_CSV_FILE_FOR_TEMPLATE' | translate }}
          </a>
        </p>
      </div>
    </div>
  </section>
</div>

<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#E10800 " type = "square-jelly-box" [fullScreen] = "true">
  <p style="color: #E10800 " > {{ 'COMMON.LOADING' | translate }} </p>>
</ngx-spinner>