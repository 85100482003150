import { Component, OnInit, Renderer2 } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AppService } from 'src/app/utils/services/app.service';
import { Router } from '@angular/router';
import { console_log, DataProvider } from 'src/providers/data/data';
import { CallapiService } from 'src/providers/rest/callapi.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MANUAL_PDF_URL, TERMS_PDF_URL } from '../../../environments/environment';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  public Notice_Data = [];
  public Scope = '1';
  public Corporation_Form;
  public Users;
  public promotion_code1;
  public promotion_code2;
  constructor(
    private renderer: Renderer2,
    private appService: AppService,
    private router: Router,
    public data: DataProvider,
    public rest: CallapiService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit() {
    this.data.menuPageTitle = 'contract-home';
    this.widthChanger('main-header', 742);
    this.widthChanger('content-wrapper', 742);
    this.spinner.show();
    this.rest.Get_LoginData(this.data.uuid, this.data.loggedEmail).subscribe(
      (res1) => {
        localStorage.setItem('loginData', JSON.stringify(res1));
        this.rest.Get_ContractsId(this.data.uuid, JSON.parse(localStorage.getItem('loginData')).data.uuid).subscribe(
          (res) => {
            console_log(res);
            localStorage.setItem('loginData', JSON.stringify({
              code: res1.code,
              data: {
                company_name: res1.data.company_name,
                corporation_no: res1.data.corporation_no,
                user_name: res1.data.user_name,
                uuid: res1.data.uuid,
                corporation_form : res.data.corporation_form,
              }
            }));
            this.Corporation_Form = res.data.corporation_form;
            this.promotion_code1 = res.data.promotion_code1;
            this.promotion_code2 = res.data.promotion_code2;
            this.Users = res.data.users;
            this.rest.Get_Notice(this.data.uuid, this.Scope).subscribe(
              (res)=>{
                console_log(res);
                this.Notice_Data = res.data;
                this.spinner.hide();
              },
              (err) => {
                console_log(err);
                this.spinner.hide();
                this.router.navigate(['/contract-err', {code: err.error.error.code}]);
              }
            );
          },
          (err) =>{
            console_log(err);
            this.spinner.hide();
            this.router.navigate(['/contract-err', {code: err.error.error.code}]);
          }
        );
      },
      (err) => {
        console_log(err);
        this.router.navigate([
          '/contract-error',
          { code: err.error.error.code, id: this.data.uuid },
        ]);
        this.spinner.hide();
      }
    );
  }

  widthChanger(className, width) {
    const headers = document.getElementsByClassName(className);
    for (let i = 0; i < headers.length; i++) {
      const header = headers[i] as HTMLElement;
      header.style.minWidth = width.toString() + 'px';
    }
  }
  ManualPDF(){
    window.open(MANUAL_PDF_URL, '_blank', '');
  }
  TermsPDF(){
    window.open(TERMS_PDF_URL, '_blank', '');
  }
}
