import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { console_log, DataProvider } from 'src/providers/data/data';
import { CallapiService } from 'src/providers/rest/callapi.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-list-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss'],
})
export class ListDetailComponent implements OnInit {
  public User_Code;
  public User_Last_Name;
  public User_First_Name;
  public Mail_Address;
  public Number;
  public Use_Status;
  public Confirm_Flg
  public thenBlock;
  public elseBlock;
  public send_button;
  public pages1;
  public Type;
  public button;
  public detail;
  public corporation_form;


  constructor(private router: Router,
    private route: ActivatedRoute,
    public data: DataProvider,
    public rest: CallapiService,
    private spinner: NgxSpinnerService) {}

  ngOnInit() {
    this.data.menuPageTitle = 'contract-user-list';
    this.User_Code = this.route.snapshot.paramMap.get('user_code');
    this.spinner.show();
    var type = JSON.parse(localStorage.getItem('loginData')).data.corporation_no.split('');
    this.corporation_form = JSON.parse(localStorage.getItem('loginData')).data.corporation_form;
    console_log(this.corporation_form);
    this.Type = type[0];
    // if(this.Type == 'B' || this.Type == 'C'){
      this.rest.Get_Screenauth(this.data.uuid).subscribe(
        (res) => {
          console_log(res);
          if (res.code == 'S000001') {
            this.pages1 = res.data;
            for (let i = 0; i < this.pages1.length; i++) {
              if (this.pages1[i].pagename == 'contract-user-list-detail') {
                console_log('contract-user-list-detail');
                if (this.pages1[i].auth == 'F') {
                  this.detail = true;
                }
                if (this.pages1[i].auth == 'R') {
                  this.detail = false;
                }
              }
              if (this.pages1[i].pagename == 'contract-user-list-authmail-conf') {
                console_log('contract-user-list-authmail-conf');
                if (this.pages1[i].auth == 'F' || this.pages1[i].auth == 'R') {
                  this.send_button = true;
                }
                if (this.pages1[i].auth == 'D') {
                  this.send_button = false;
                }
              }
              if (this.pages1[i].pagename == 'contract-user-list-edit') {
                console_log('contract-user-list-edit');
                if (this.pages1[i].auth == 'F' || this.pages1[i].auth == 'R') {
                  this.button = true;
                }
                if (this.pages1[i].auth == 'D') {
                  this.button = false;
                }
              }
            }
          }
        },
      );
    // }
    this.rest.Get_MembersId(this.data.uuid, this.User_Code).subscribe(
      (res)=>{
        console_log(res);
        this.User_Last_Name = res.data.user_last_name;
        this.User_First_Name = res.data.user_first_name;
        this.Mail_Address = res.data.mail_address;
        this.Number = res.data.user_no;
        this.Use_Status = res.data.use_status;
        // this.Confirm_Flg = res.data.confirm_flg;
        this.spinner.hide();
      },
      (err)=>{
        console_log(err);
        this.spinner.hide();
        this.router.navigate(['/contract-err', {code: err.error.error.code}]);
      }
    );
  }

  SendMailDetial(){
    document.getElementById('userdetail').style.display='none';
    document.getElementById('sendverification').style.display='block';
    document.getElementById('sendverificationok').style.display='none';
  }
  back(){
    document.getElementById('userdetail').style.display='block';
    document.getElementById('sendverification').style.display='none';
    document.getElementById('sendverificationok').style.display='none';
  }
  SendMail(){
    const body = {
      data: {
        user_uuid: this.data.uuid,
        user_code: this.User_Code,
      },
    };
    console_log(body)
    this.spinner.show();
    this.rest.Send_AuthMail(this.data.uuid, this.User_Code).subscribe(
      (res)=>{
        console_log(res);
        document.getElementById('userdetail').style.display='none';
        document.getElementById('sendverification').style.display='none';
        document.getElementById('sendverificationok').style.display='block';
        this.spinner.hide();
      },
      (err)=>{
        console_log(err);
        this.spinner.hide();
        this.router.navigate(['/contract-err', {code: err.error.error.code}]);
      }
    );
    this.spinner.hide();
  }
  deleteUserInfo(){
    this.router.navigate(['/contract-user-list-delete', {user_code: this.User_Code}]);
  }
  changeUserInfo(){
    this.router.navigate(['/contract-user-list-edit', {user_code: this.User_Code}]);
  }
  return(){
    this.router.navigate(['/contract-user-list']);
  }
}
