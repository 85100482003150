<div class="main-padding">
  <h3 class="header-bottom">
    {{ 'SIDEBAR_MENU.REGISTER_ALL_USER' | translate }}
  </h3>
  <!-- Section №2 -->
  <section class="content" id="success">
    <div class="card-body">
      <div class="text-center">
        <h3 style="margin-top: 90px; text-align: center">
          {{ 'REGISTER_USER.REGISTER_COMPLETED' | translate }}
        </h3>
      </div>
    </div>
    <div class="card-body">
      <div class="text-center">
        <p class="card-result">
          {{ 'REGISTER_ALL_USER.CONTINUE_REGISTER_CLICK_BACK_MSG' | translate }}
          <br />
          {{ 'REGISTER_ALL_USER.SEND_VERIFCD_CLICK_SEND_MSG' | translate }}
          <br />
          {{ 'REGISTER_ALL_USER.MAY_TAKE_SOME_TIME_COMPLETEREG_MSG_TEXT_1' | translate }}
          <br />
          {{ 'REGISTER_ALL_USER.MAY_TAKE_SOME_TIME_COMPLETEREG_MSG_TEXT_2' | translate }}
        </p>
      </div>
    </div>
    <!-- BUTTON -->
    <div class="text-center">
      <div class="col-12 two-btn">
        <div class="display">
          <a (click)="back()" class="btn-grey">
            <i></i> {{ 'BUTTON_TYPES.BACK' | translate }}
          </a>
        </div>
        <div class="display">
          <a (click)="authmail()" class="btn-pink">
            <i></i> {{ 'BUTTON_TYPES.ALL_VERIFICATION_CODE_SEND' | translate }}
          </a>
        </div>
      </div>
    </div>
    <!-- BUTTON end -->
    <div class="text-center">
      <p class="card-title">
        「<a (click)="changeuserinfo()" class="text2">
          {{ 'BUTTON_TYPES.REGISTRATION_LIST' | translate }}
          </a>
          {{ 'REGISTER_ALL_USER.VIEW_REGISTERED_CONTENTS_MSG' | translate }}
      </p>
    </div>
  </section>
</div>

<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#E10800 " type = "square-jelly-box" [fullScreen] = "true">
  <p style="color: #E10800 " > {{ 'COMMON.LOADING' | translate }} </p>>
</ngx-spinner>