<div style="position: absolute;top: 0;left: 0;width: 100%;">
    <nav class="navbar navbar-expand navbar-white navbar-light" style="padding-left: 0px;margin-left: 0;">
      <a [routerLink]="['/home']">
      <img
          src={{logoUrl}}
          alt="AdminLTE Logo"
          class="brand-image"
        /></a>
    </nav>
  </div>
  <div class="login-box">
    <div class="login-logo">
      <i class='warning'></i>
    </div>
    <div class="text-center" style="margin-top: -10px;">
      <p>{{ 'ERROR.' + errorCode | translate }}</p>
    </div>
  </div>
