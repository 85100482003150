<div class="main-padding">
  <h3 class="header-bottom">
    {{ 'SIDEBAR_MENU.REGISTER_ALL_USER' | translate }}
  </h3>
  <section class="content">
    <div class="card-body">
      <div class="text-center">
        <h3 style="margin-top: 90px; text-align: center" id='title'>
          {{ 'REGISTER_ALL_USER_FAIL.REGISTRATION_WAS_COMPLETE_TEXT_1' | translate }} <br> 
          {{ 'REGISTER_ALL_USER_FAIL.PARTIAL_REGISTRATION_FAILED_TEXT_2' | translate }}
        </h3>
      </div>
    </div>
    <div class="card-body">
      <div class="text-center">
        <p class="card-result">
          {{ 'REGISTER_ALL_USER_FAIL.PLEASE_CHECK_THE_ERROR_FILE_FOR_DETAILS' | translate }}
        </p>
        <p class="card-result">
          <a (click)="downloadCSV()" class="csv-url">
            {{ 'REGISTER_ALL_USER_FAIL.DOWNLOAD_ERROR_FILE' | translate }}
          </a>
        </p>
        <p class="card-result">
          {{ 'REGISTER_ALL_USER_FAIL.BACK_BUTTON_COMMENT' | translate }}
        </p>
      </div>
    </div>
    <!-- BUTTON -->
    <div class="text-center button_margin_bottom">
      <div class="col-12 ">
        <div class="display">
          <a (click)="back()" class="btn-grey">
            <i></i> {{ 'BUTTON_TYPES.BACK' | translate }}
          </a>
        </div>
      </div>
    </div>
    <!-- BUTTON end -->
    <div class="text-center">
      <p class="card-title">
        「<a (click)="changeuserinfo()" class="text2">
          {{ 'BUTTON_TYPES.REGISTRATION_LIST' | translate }}
          </a>
          {{ 'REGISTER_ALL_USER.VIEW_REGISTERED_CONTENTS_MSG' | translate }}
      </p>
    </div>
  </section>
</div>
<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#E10800 " type = "square-jelly-box" [fullScreen] = "true">
  <p style="color: #E10800 " > {{ 'COMMON.LOADING' | translate }} </p>>
</ngx-spinner>